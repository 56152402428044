<template>
    <div>
        <mds-layout-grid>
            <mds-row style="margin: 16px;display: grid;">
            <div class="breadCrumsDiv">
                <span class="breadCrumsLink" @click="onCancelClick">{{this.selectedEntity.entityname}}</span><span> / Edit External Codes - {{this.selectedEntity.entityname}}</span> 
            </div>
            <div>
                <h2 class="template-header">Edit External Codes - {{this.selectedEntity.entityname}}</h2>
            </div>
            <div style="height:68vh">
            <mds-section border="primary" title="Identifiers">
                <template v-if="!showLoader">
               <mds-section  container title="Identifier" border="secondary" class="externalCodeContainerSection" v-for="externalCodeItems,index in externalCodePayload" :key="index">
                    <mds-layout-grid>
                        <mds-row>
                            <mds-col :cols="3">
                                <mds-form>
                                    <mds-select
                                        :error="showIdentifierTypeError" 
                                        :error-text="[`Identifier Type is required.`]"
                                        label="Identifier Type"
                                        placeholder="Select an option"
                                        v-model="externalCodeItems.externalcodeid"
                                        :options="identifierTypes"
                                    ></mds-select>
                                </mds-form>
                            </mds-col>
                            <mds-col :cols="3">
                                <mds-form>
                                    <mds-input label="Code" :error="showCodeInputError" :error-text="[`Code is required.`]" v-model="externalCodeItems.codevalue" maxlength="10" ></mds-input>
                                </mds-form>
                            </mds-col>
                            <mds-col :cols="3">
                                <mds-form>
                                    <mds-input class="readOnlyInput" readonly v-model="externalCodeItems.modifiedby" label="Modified By"></mds-input>
                                </mds-form>
                            </mds-col>
                            <mds-col :cols="3">
                                <mds-form>
                                    <mds-input class="readOnlyInput" readonly v-model="externalCodeItems.modified" label="Last Modified"></mds-input>
                                </mds-form>
                            </mds-col>
                            <mds-col style="margin-top: 16px;" :cols="3">
                                 <mds-form>
                                    <mds-input label="PriceSosDate" type="date" v-model="externalCodeItems.pricesosdate"></mds-input>
                                </mds-form>
                            </mds-col>
                            <mds-col style="margin-top: 16px;" :cols="3">
                                 <mds-form>
                                    <mds-input label="DividendSosDate" type="date" v-model="externalCodeItems.dividendsosdate"></mds-input>
                                </mds-form>
                            </mds-col>
                            <mds-col style="margin-top: 16px;" :cols="3">
                                 <mds-form>
                                    <mds-input label="ValuationSosDate" type="date" v-model="externalCodeItems.valuationsosdate"></mds-input>
                                </mds-form>
                            </mds-col>
                        </mds-row>
                    </mds-layout-grid>
                </mds-section>
            </template>
            </mds-section>
            </div>
            <div v-if="!showLoader" style="width:100%;margin-top:16px;border-top: 1px solid #CCCCCC;
    padding-top: 16px;">
                <button-component
                    :onClick="onCancelClick"
                    buttonName="Cancel"
                    buttonVariation="secondary"
                >
                </button-component>
                <button-component
                    :onClick="saveEditedData"
                    buttonName="Save"
                    buttonVariation="primary"
                    style="float: right;"
                >
                </button-component> 
            </div>
            </mds-row>
        </mds-layout-grid>
           <notification-component 
     v-if="showNotification" 
     :notificationMessage="notificationMessage" 
     :notificationType="notificationType"
     ></notification-component>
        <div class="loaderCursor" v-if="showLoader">
            <loader-component loaderSize="large"></loader-component>
         
        </div>
    </div>
</template>
<script>
import store from "@/store";
import get from "lodash/get";
import { mapState } from "vuex";
import { EXTERNAL_CODES_ACTIONS } from "@/store/modules/editExternalCodes/contants";
import { MdsLayoutGrid, MdsRow,MdsCol  } from '@mds/layout-grid'
import MdsSection from '@mds/section'
import MdsSelect from '@mds/select'
import MdsInput from '@mds/input'
import MdsForm from '@mds/form'
import LoaderComponent from '../ui_component/loaderComponent.vue';
import ButtonComponent from '../ui_component/ButtonComponent.vue';
import NotificationComponent from '../ui_component/notificationComponent.vue';
import { dateFormatterMixin } from '../../mixins/date.js';
export default {
    components:{
        MdsLayoutGrid, MdsRow,MdsCol,
        MdsSection,
        LoaderComponent,
        MdsSelect,
        MdsForm,
        MdsInput,
        ButtonComponent,
        NotificationComponent,
    },
    beforeCreate() {
        this.$store = store;
    },
    mixins: [dateFormatterMixin],
    data(){
        return{
            showIdentifierTypeError:false,
            showCodeInputError:false,
            showNotification:false,
            notificationMessage:'',
            notificationType:'',
            identifierTypes:[],
            selectedEntity:'',
            showLoader:false,
            codevalue:"",
            dividendsosdate:null,
            entityexternalcodeid:0,
            externalcodeid:null,
            modified:null,
            modifiedby:"",
            pricesosdate:null,
            valuationsosdate:null,
            externalCodePayload:[
                    {
                        codevalue:"",
                        dividendsosdate:null,
                        entityexternalcodeid:0,
                        externalcodeid:null,
                        modified:null,
                        modifiedby:"",
                        pricesosdate:null,
                        valuationsosdate:null
                    }
            ],
            externalCodeUpdatePayloadFormat:{
                "codeValue": "",
                "externalCodeId": 0,
                "entityExternalCodeId": 0,
                "priceSosDate": "",
                "dividendSoSDate": "",
                "valuationSoSDate": "",
                "entityId": 0
                }
        }
    },
     computed:{
        ...mapState({
            editExternalCodeList(state) {
                return get(state, "editExternalCodes.externalCodesListDetails.externalCodesListDetails", []);
            },
            editExternalCodeListLoader(state) {
                return get(state, "editExternalCodes.externalCodesListDetails.__loading__", false);
            },
            editExternalCodeListError(state) {
                return get(state, "editExternalCodes.externalCodesListDetails.__error__", null);
            },
            updateExternalCodesDetails(state) {
                return get(state, "editExternalCodes.updateExternalCodes.updateExternalCodes", []);
            },
            updateExternalCodesDetailsLoader(state) {
                return get(state, "editExternalCodes.updateExternalCodes.__loading__", false);
            },
            updateExternalCodesDetailsError(state) {
                return get(state, "editExternalCodes.updateExternalCodes.__error__", null);
            },
        }),
        
    },
    async mounted(){
        const { entityname, entityid } = this.$route.query;
        this.selectedEntity = { entityname, entityid };
        if(entityid){
            this.fetchExternalCodeListData(entityid)
        }
    },
    methods:{
        async saveEditedData(){
            this.showCodeInputError=false
            this.showIdentifierTypeError=false
            if(this.externalCodePayload[0].codevalue.length > 0 && this.externalCodePayload[0].externalcodeid != null){
            this.externalCodeUpdatePayloadFormat.codeValue= this.externalCodePayload[0].codevalue
            if(this.externalCodePayload[0].externalcodeid == null){
                this.externalCodeUpdatePayloadFormat.externalCodeId= 0
            }else{
                this.externalCodeUpdatePayloadFormat.externalCodeId= this.externalCodePayload[0].externalcodeid
            }
            this.externalCodeUpdatePayloadFormat.entityExternalCodeId= this.externalCodePayload[0].entityexternalcodeid
            this.externalCodeUpdatePayloadFormat.priceSosDate= this.externalCodePayload[0].pricesosdate
            this.externalCodeUpdatePayloadFormat.dividendSoSDate=this.externalCodePayload[0].dividendsosdate
            this.externalCodeUpdatePayloadFormat.valuationSoSDate=this.externalCodePayload[0].valuationsosdate
             if(this.externalCodeUpdatePayloadFormat.priceSosDate != null){
                    this.externalCodeUpdatePayloadFormat.priceSosDate = this.convertAdditionalSearchDate(this.externalCodeUpdatePayloadFormat.priceSosDate)
                 }
                if(this.externalCodeUpdatePayloadFormat.dividendSoSDate != null){
                    this.externalCodeUpdatePayloadFormat.dividendSoSDate= this.convertAdditionalSearchDate(this.externalCodeUpdatePayloadFormat.dividendSoSDate)
                  }
                if(this.externalCodeUpdatePayloadFormat.valuationSoSDate != null){
                    this.externalCodeUpdatePayloadFormat.valuationSoSDate= this.convertAdditionalSearchDate(this.externalCodeUpdatePayloadFormat.valuationSoSDate)
                }
             this.externalCodeUpdatePayloadFormat.entityId = this.selectedEntity.entityid
             this.showLoader=true
             let data = this.externalCodeUpdatePayloadFormat
            await this.$store.dispatch(EXTERNAL_CODES_ACTIONS.UPDATE_EXTERNAL_CODES,{data})
            if(this.updateExternalCodesDetailsError == null){
                this.$router.push({
                    name: "Add Investment",
                    params: {
                        entityid: this.selectedEntity.entityid,
                        showNotification:true,
                        notificationMessage:"The external identifiers were saved."
                    },
                });
            }else{
                let errorString =''
                if(this.updateExternalCodesDetailsError.data.result.length > 0){
                    for(let i = 0;i<this.updateExternalCodesDetailsError.data.result.length;i++){
                        if(i==0){
                            if(this.updateExternalCodesDetailsError.data.result[i].errordescription){ 
                                errorString=this.updateExternalCodesDetailsError.data.result[i].errordescription
                            }else{
                                errorString=this.updateExternalCodesDetailsError.data.message
                                break;
                            }
                        }else{
                            if(this.updateExternalCodesDetailsError.data.result[i].errordescription){ 
                             errorString=errorString+','+this.updateExternalCodesDetailsError.data.result[i].errordescription
                            }else{
                                errorString=this.updateExternalCodesDetailsError.data.message
                                break;
                            }
                        }
                    }
                }else{
                    errorString=this.updateExternalCodesDetailsError.data.message
                }
                
                const obj = {
                    ntfnMsg: errorString,
                    ntfnType: "error",
                    ntfnKey: "error-default",
                };
                this.notificationResponse(obj);
            }
            this.showLoader=false
            }else{
                if(!this.externalCodePayload[0].codevalue.length > 0){
                    this.showCodeInputError=true
                }
                if(this.externalCodePayload[0].externalcodeid == null){
                     this.showIdentifierTypeError=true
                }
            }
        },
        notificationResponse({
            ntfnMsg,
            ntfnType,
            ntfnKey,
        }) {
                 this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
                setTimeout(() => {
                    this.showNotification = false;
                }, 2000);
        },
        onCancelClick(){
             this.$router.push({
                    name: "Add Investment",
                    params: {
                        entityid: this.selectedEntity.entityid,
                        showNotification:false,
                        notificationMessage:""
                    },
                });
        },
        async fetchExternalCodeListData(entityId){
            this.showLoader=true
            await this.$store.dispatch(EXTERNAL_CODES_ACTIONS.FETCH_EXTERNAL_CODES_LIST,{entityId})
            if(this.editExternalCodeListError == null){
                this.identifierTypes=[]
                if(this.editExternalCodeList.externalcodes.length > 0){
                    this.externalCodePayload=this.editExternalCodeList.externalcodes
                    this.externalCodePayload.forEach(item=>{
                        item.pricesosdate = this.convertReportingParameterDate(item.pricesosdate)
                        item.dividendsosdate= this.convertReportingParameterDate(item.dividendsosdate)
                        item.valuationsosdate= this.convertReportingParameterDate(item.valuationsosdate)
                        item.modified= this.convertUTCDate(item.modified)
                    })
                }
                if(this.editExternalCodeList.externalcodeslist.length > 0){
                    for(let i=0;i<this.editExternalCodeList.externalcodeslist.length;i++){
                        let typeObject={
                            text:this.editExternalCodeList.externalcodeslist[i].name,
                            value:this.editExternalCodeList.externalcodeslist[i].val
                        }
                        this.identifierTypes.push(typeObject)
                    }
                }
            }
            this.showLoader=false
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.breadCrumsDiv{
    margin-top: 16px;
    font-size: 14px;
    line-height: 18px;
}
.breadCrumsLink{
    text-decoration: underline;
    color: #006FBA;
    cursor: pointer;
}
.template-header {
  @include mds-level-2-heading();
     margin-bottom: 16px;
    margin-top: 16px;
}
.externalCodeContainerSection::v-deep .readOnlyInput .mds-input___VueMDS3Demo{
    
    box-shadow: none;
    background: none;
    padding-left: 0;

}
</style>